<template>
  <van-dialog v-model:show="showDialog" title="支付订单" @confirm="payOrder" :show-cancel-button="true"
    :before-close="beforeClose" class="padding-15px">
    <div class="font-size-16px">
      <div class="padding-7px">
        商品名称：{{ orderInfo.product_name }}
      </div>
      <div class="padding-7px" v-if="orderInfo.product_id != 8">
        时长：{{ orderInfo.productoption_name }}
      </div>
      <div class="padding-7px">
        价格：<span class="font-weight-600" style="color: red">￥{{ orderInfo.price }}</span>
      </div>
    </div>
    <div class="flex-center padding-7px" style="font-size: 16px">
      请选择你的付款方式
    </div>
    <div class="">
      <van-radio-group v-model="payMethod" class="ml-4">
        <van-radio class="padding-7px" :name="0"
          :disabled="orderInfo.product_id == 8 || amount < orderInfo.price"><van-icon name="balance-o"
            color="#EF5350" />
          余额:
          <span>￥{{ amount }}</span>
          <span v-if="orderInfo.product_id == 8" style="font-size: 0.7em;">
            不支持此种方式
          </span>
          <span v-if="orderInfo.product_id != 8 && amount < orderInfo.price" style="font-size: 0.7em;">
            余额不足
          </span>
        </van-radio>
        <van-radio class="padding-7px" :name="1"><van-icon name="alipay" color="#1989FA" /> 支付宝</van-radio>
        <van-radio class="padding-7px" :name="2"><van-icon name="wechat-pay" color="#36D7B7" /> 微信 </van-radio>
      </van-radio-group>
    </div>
  </van-dialog>
  <PayCodeVue v-model="orderInfo.id" v-if="payCodeShow" @onwxClose="wxClose" />
</template>

<script>
import { showDialog } from "vant";
import { reactive, toRefs, onMounted } from "vue";
import globals from "@/utils/globals";
import PayCodeVue from './payCode.vue';

export default {
  name: "PayBoxVue",
  components: {
    PayCodeVue,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ["onClose"],
  setup(props, { emit }) {
    const state = reactive({
      payMethod: 0,
      amount: 100,
      orderInfo: {},
      showDialog: true,
      paying: false,
      payCodeShow: false,
      coninfo: {},
      checkCount: 0,
    });
    const loadOrder = () => {
      globals.showLoading();
      globals.get("user/query/order-detail/" + props.modelValue, {}, (data) => {
        globals.hideLoading();
        if (data.rc) {
          state.orderInfo = data.data;
          if (state.orderInfo.product_id == 8) {
            state.payMethod = 1;
          }
          if (state.orderInfo.price > state.amount) {
            state.payMethod = 1;
          }
        } else {
          sendCloseEvent({ rc: false, message: '未找到订单信息' });
        }
      });
    };
    const payOrder = () => {
      state.paying = true;
      if (state.payMethod == 0) {
        callWalletPay();
      }
      if (state.payMethod == 1) {
        callAliPay();
      }
      if (state.payMethod == 2) {
        isWeixinBrowser();
      }
    };
    const callWalletPay = () => {
      globals.post(
        "user/order/amount-pay/" + state.orderInfo.id,
        {},
        (data) => {
          sendCloseEvent(data);
        }
      );
    };
    const callAliPay = () => {
      showDialog({
        title: "提示",
        message: "订单支付中... ",
      }).then(() => {
        globals.get("user/order/order-is-pay/" + state.orderInfo.id, {}, (data) => {
          state.paying = false;
          sendCloseEvent(data);
        });
      });
      var info = navigator.userAgent;
      var isPhone = /mobile/i.test(info);
      if (isPhone) {
        window.open(window.roots + "/app-alipay/h5-wap/" + state.orderInfo.id);
      } else {
        window.open(window.roots + "/app-alipay/qr/" + state.orderInfo.id);
      }
      loopCheckOrder(state.orderInfo.id);
    };
    const loopCheckOrder = (id) => {
      state.checkCount++;
      if (state.checkCount >= 30) {
        sendCloseEvent({ rc: false, message: "未查询到订单支付信息, 请自行确认" });
      }
      globals.get("user/order/order-is-pay/" + id, {}, (data) => {
        if (state.paying == false) {
          return;
        }
        if (data.rc) {
          state.paying = false;
          sendCloseEvent(data);
        } else {
          setTimeout(() => {
            loopCheckOrder(id);
          }, 3000);
        }
      });
    };
    const beforeClose = () => {
      if (state.paying) {
        return;
      }
      globals.errorMsg("取消支付");
      sendCloseEvent(null);
    };
    const cancelPay = () => {
      globals.errorMsg("取消支付");
      sendCloseEvent(null);
    };
    const wechatNativePay = () => {
      globals.showLoading();
      globals.post(`/app-wechat-pay/nativePay/${state.orderInfo.id}`, {}, (res) => {
        if (res.rc) {
          globals.hideLoading();
          state.payCodeShow = true;
          loopCheckOrder(state.orderInfo.id);
        }
      });
    };
    const wxClose = () => {
      sendCloseEvent(null);
    }
    const wechatPay = () => {
      globals.post(`/app-wechat-pay/wechatPay/${state.orderInfo.id}`, {}, (data) => {
        if (data.rc) {
          wxConfig(data.data);
          loopCheckOrder(state.orderInfo.id);
        }
      });
    };
    const onBridgeReady = (data) => {
      globals.get(`/app-wechat-pay/buglog`, { msg: data });
      try {
        window.WeixinJSBridge.invoke('getBrandWCPayRequest', {
          "appId": data.appId,
          "timeStamp": data.timeStamp,
          "nonceStr": data.nonceStr,
          "package": data.package,
          "signType": "RSA",
          "paySign": data.paySign
        }, function (payRes) {
          //使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (payRes.err_msg == "get_brand_wcpay_request:ok") {
            globals.successMsg("支付成功");
          } else if (payRes.err_msg == "get_brand_wcpay_request:cancel") {
            globals.errorMsg("支付失败,用户取消支付!");
          } else if (payRes.err_msg == "get_brand_wcpay_request: fail") {
            globals.errorMsg("支付失败!");
          }
        });
      } catch (error) {
        globals.get(`/app-wechat-pay/buglog`, { msg: error.message });
      }
    }
    const wxConfig = (e) => {
      globals.hideLoading();
      if (typeof window.WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
        }
        setTimeout(() => {
          onBridgeReady(e);
        }, 1000);
      } else {
        onBridgeReady(e);
      }
    };
    const isWeixinBrowser = () => {
      //是否是微信浏览器
      if (/(micromessenger)/i.test(navigator.userAgent)) {
        if (/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)) {
          //'电脑微信或者微信开发者工具'
          wechatPay();
        } else {
          //手机微信打开的浏览器
          wechatPay();
        }
      } else {
        wechatNativePay();
      }
    }
    const sendCloseEvent = (res) => {
      state.checkCount = 30;
      emit("onClose", res, state.orderInfo);
    };
    onMounted(() => {
      state.amount = globals.store.state.userinfo.wallet;
      loadOrder();
    });
    return {
      ...toRefs(state),
      payOrder,
      cancelPay,
      beforeClose,
      wxClose,
    };
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.dialog {
  position: fixed;
}

.content {
  background-color: #fff;
  height: 100%;
  margin-top: -20px;
}

.cut-off-rule {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f6f6f6;
}

.tag-block {
  margin: 0 8px 8px 0;
  background-color: #f7f8fa;
  padding: 10px;
}

.tag-block:hover {
  cursor: pointer;
}

.on-tag-block {
  color: #ef5350 !important;
  background-color: #f6e8e9;
}

.border-top-radius-15px {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

@media screen and (max-width: 488px) {
  .w-100vw {
    width: 100vw;
  }

  .h-85vh {
    height: 85vh;
  }

  .bottom {
    bottom: 0;
  }
}

@media screen and (min-width: 488px) {
  .border-bottom-radius-15px {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .w-500px {
    width: 500px;
  }

  .mt--15vh {
    margin-top: -15vh;
  }
}
</style>