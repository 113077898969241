import service from './service';
import md5 from 'js-md5';
import store from '../store/index';
import { showSuccessToast, showFailToast } from 'vant';
import router from '../router/index';
import hazecore from '../utils/hazecore';

let local = localStorage, localURL = "";
/**
 * 发送get请求
 */
const get = (url, arg1, arg2) => {
    let form = typeof arg2 === 'function' ? arg1 : {};
    let callback = typeof arg2 === 'function' ? arg2 : arg1;
    service.doRequest('GET', "/" + url, form, callback);
}
/**
 * 发送post请求
 */
const post = (url, arg1, arg2) => {
    let form = typeof arg2 === 'function' ? arg1 : {};
    let callback = typeof arg2 === 'function' ? arg2 : arg1;
    service.doRequest('POST', "/" + url, form, callback);
}
/**
 * 通用加载分页数据接口
 */
const loadRecords = (module, arg1, arg2) => {
    let form = typeof arg2 === 'function' ? arg1 : {};
    let callback = typeof arg2 === 'function' ? arg2 : arg1;
    service.doRequest('GET', "/" + module + '/list', form, callback);
}
/**
 * 通用save请求接口
 */
const saveRecord = (module, id, form, callback) => {
    service.doRequest('POST', "/" + module + '/save/' + id, form, callback);
}
/**
 * 通用load请求接口
 */
const loadRecord = (module, id, callback, querys) => {
    querys = querys || {};
    let qstr = "", keys = Object.keys(querys);
    for (let i = 0; i < keys.length; i++) {
        qstr += (qstr == '' ? '' : '&') + keys[i] + '=' + querys[keys[i]];
    }
    service.doRequest('GET', "/" + module + '/load/' + id + (qstr == '' ? '' : '?' + qstr), {}, callback);
}
/**
 * 通用del请求接口
 */
const deleteRecord = (module, id, callback) => {
    service.doRequest('DELETE', "/" + module + '/delete/' + id, {}, callback);
}
/**
 * 通用switch请求接口
 */
const switchRecord = (module, id, callback) => {
    service.doRequest('POST', "/" + module + '/switch/' + id, {}, callback);
}
/**
 * 加载分页数据接口
 */
const loadPageList = (module, arg1, arg2) => {
    let form = typeof arg2 === 'function' ? arg1 : {};
    let callback = typeof arg2 === 'function' ? arg2 : arg1;
    service.doRequest('GET', "/" + module, form, callback);
}
/* 
     用户注销
     @param callback 请求回调函数
 */
const signout = (callback) => {
    service.doRequest('DELETE', '/customer/signin', {}, callback);
}
const successMsg = (msg) => {
    showSuccessToast(msg);
}
const errorMsg = (msg) => {
    showFailToast(msg);
}
/*
    页面跳转校验
*/
const goToPage = (url) => {
    if (url == '/') {
        router.push(url);
        return;
    }
    if (store.state.userinfo == null) {
        router.replace('/signin');
        return;
    }
    router.push(url);
}

const showLoading = () => {
    store.state.loading = true;
};

const hideLoading = () => {
    store.state.loading = false;
};

const isWechat = () => {
    return /(micromessenger)/i.test(navigator.userAgent) || /(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent);
};

const notEmptyStr = (str) => {
    return str != null && str != "" && str != "null" && str != "undefined" && str != undefined;
};
const isEmptyStr = (str) => {
    return str == null || str == "" || str == "null" || str == "undefined" || str == undefined;
};

export default {
    loadRecords,
    saveRecord,
    loadRecord,
    deleteRecord,
    switchRecord,
    get,
    post,
    md5,
    store,
    local,
    successMsg,
    errorMsg,
    signout,
    router,
    goToPage,
    loadPageList,
    showLoading,
    hideLoading,
    hazecore,
    localURL,
    isWechat,
    notEmptyStr,
    isEmptyStr
}