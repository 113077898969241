<template>
  <div class="rightNav">
    <div class="fixed-item flex items-center add-cursor" @click="goToPage('/user/giftCard')" :style="currPath == '/user/giftCard'
      ? 'background-color: #3a8ac9;'
      : 'background-color: #fff'
      ">
      <div class="mx-auto" style="">
        <div class="flex-center">
          <van-icon name="gift-card" size="25" :color="currPath != '/user/giftCard' ? '#3a8ac9' : '#fff'"
            class="breathe" />
        </div>
        <div class="font-size-12px desc" :style="currPath != '/user/giftCard' ? 'color: #3a8ac9;' : 'color: #fff'
      ">
          礼品卡
        </div>
      </div>
    </div>
    <transition name="van-fade">
      <div class="fixed-item flex items-center add-cursor" v-show="isShow" @click="goToPage('/user/share')" :style="currPath == '/user/share'
      ? 'background-color: #3a8ac9;'
      : 'background-color: #fff'
      ">
        <div class="mx-auto">
          <div class="flex-center">
            <van-icon name="share" size="25" :color="currPath != '/user/share' ? '#3a8ac9' : '#fff'" />
          </div>
          <div class="font-size-12px desc" :style="currPath != '/user/share' ? 'color: #3a8ac9;' : 'color: #fff'
      ">
            推广返现
          </div>
        </div>
      </div>
    </transition>
    <transition name="van-fade">
      <div class="fixed-item flex items-center add-cursor" v-show="isShow" @click="goToPage('open-service')">
        <div class="mx-auto">
          <div class="flex-center">
            <van-icon name="service" size="25" />
          </div>
          <div class="font-size-12px desc">在线服务</div>
        </div>
      </div>
    </transition>
    <transition name="van-fade">
      <div class="fixed-item flex items-center add-cursor" v-show="isShow" @click="showDialog = !showDialog">
        <div class="mx-auto">
          <div class="flex-center">
            <van-icon name="wechat" size="25" color="#3a8ac9" />
          </div>
          <div class="font-size-12px desc">公众号</div>
        </div>
      </div>
    </transition>
    <div class="fixed-item flex items-center close-btn" @click="isShow = !isShow">
      <div v-show="isShow" class="mx-auto">
        <div class="flex-center">
          <van-icon name="clear" size="25" color="#3a8ac9" />
        </div>
      </div>

      <div v-show="!isShow" class="mx-auto">
        <div class="flex-center">
          <van-icon name="wap-nav" size="25" color="#3a8ac9" />
        </div>
      </div>
    </div>

    <van-dialog v-model:show="showDialog" title="长按图片或扫码关注">
      <div class="flex-center">
        <div class="flex-center" style="border-radius: 12px; border: 1px solid rgb(237, 241, 244)">
          <van-image width="160" height="160" :src="serviceQrCode" />
        </div>
      </div>
      <div class="flex-center font-size-12px padding-15px" style="color: #8f8c98">
        以便接收密码变更,续费提醒,优惠信息等等
      </div>
    </van-dialog>

    <van-dialog v-model:show="showOnlineService" title="在线客服" confirm-button-text="关闭"
      @confirm="beforeCloseOnlineService">
      <div class="flex-center">
        <div>
          <div style="height: 40px;line-height: 40px;text-align: center;" v-if="showAfterBuyTip">
            请联系客服处理订单
          </div>
          <div class="flex-center" style="border-radius: 12px; border: 1px solid rgb(237, 241, 244)">
            <van-image width="160" height="160" :src="require('@/assets/we_chat_customer_service.png')" />
          </div>
          <div>
            <div style="margin: 10px auto;text-align: center;">
              <van-button type="primary" @click="openWxServerChat">打开客服</van-button>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, watch } from "vue";
import globals from "@/utils/globals";

export default {
  name: "RightNavVue",
  emits: ["onOpenDialog"],
  setup(props, { emit }) {
    const state = reactive({
      value: "",
      showDialog: false,
      isShow: true,
      currPath: "",
      showOnlineService: false,
      showAfterBuyTip: false,
      serviceQrCode: "",
    });
    watch(
      () => globals.router.currentRoute.value.path,
      (newValue) => {
        state.currPath = newValue;
      }
    );
    watch(
      () => state.showDialog,
      (newValue) => {
        if (newValue) {
          emit("onOpenDialog", true);
        } else {
          emit("onOpenDialog", false);
        }
      }
    );
    watch(
      () => state.showOnlineService,
      (newValue) => {
        if (newValue) {
          emit("onOpenDialog", true);
        } else {
          emit("onOpenDialog", false);
        }
      }
    );
    const loadServiceQrCode = () => {
      globals.get("open/action/get-service-qr-code", {}, (data) => {
        state.serviceQrCode = data.data;
      });
    };
    const goToPage = (url) => {
      state.showAfterBuyTip = false;
      if (url == "open-service") {
        state.showOnlineService = true;
        return;
      }
      globals.goToPage(url);
    };
    const showAfterService = () => {
      goToPage("open-service");
      state.showAfterBuyTip = true;
    };
    const openWxServerChat = () => {
      // window.open("https://work.weixin.qq.com/kfid/kfc5784144a1fe05fe8");
	  window.open("https://work.weixin.qq.com/kfid/kfc548b89e86aee17b0");
    }
    const beforeCloseOnlineService = () => {
      state.showOnlineService = false;
      if (state.showAfterBuyTip) {
        globals.router.push('user/order');
      }
    }
    onMounted(() => {
      window.showAfterService = showAfterService;
      loadServiceQrCode();
    });
    return {
      ...toRefs(state),
      goToPage, openWxServerChat, beforeCloseOnlineService
    };
  },
};
</script>
<style scoped>
.rightNav {
  position: fixed;
}

.fixed-item {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border: 1px solid #3a8ac9;
  background-color: #fff;
  border-radius: 5px;
  color: #3a8ac9;
  float: right;
}

.breathe {
  animation: breath 0.8s infinite alternate;
}

@keyframes breath {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.2);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (min-width: 375px) {}

@media screen and (min-width: 425px) {}

@media screen and (max-width: 625px) {
  .rightNav {
    right: 22px;
    bottom: 22vh;
    width: 40px;
  }

  .fixed-item {
    width: 35px;
    height: 35px;
  }

  .desc {
    display: none;
  }

  .close-btn {
    width: 35px;
  }
}

@media screen and (min-width: 625px) {
  .rightNav {
    right: 22px;
    bottom: 18vh;
    height: 420px;
    width: 60px;
  }

  .fixed-item {
    width: 60px;
    height: 60px;
  }

  .desc {
    display: block;
  }

  .close-btn {
    display: none;
  }
}
</style>