import { createStore } from 'vuex';

export default createStore({
  state: {
    userinfo: {
      head_path: "https://site.gxyunyun.com/assets/cm-3d/cm3-menu-4-actived.png",
    },
    loading: false,
    signinAfter: "/",
    token: null,
  },
  getters: {
    getSigninAfter(state) {
      if (state.signinAfter == "-lock-") {
        return "/";
      }
      return state.signinAfter;
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, user) {
      state.userinfo = user;
    },
    setSigninAfter(state, url) {
      if (url == null || url == "") {
        return;
      }
      if (state.signinAfter != "/") {
        state.signinAfter = "-lock-";
        return;
      }
      if (url == "giftCard") {
        state.signinAfter = "/user/giftCard";
      } else if (url == "accounts") {
        state.signinAfter = "/user/my-accounts";
      } else if (url == "support") {
        state.signinAfter = "/user/support/new";
      } else {
        state.signinAfter = "/" + url;
      }
    },
  },
  actions: {
  },
  modules: {
  }
})
