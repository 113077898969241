<template>
  <div :class="$store.state.loading ? 'not-allowed' : ''" class="scrollTop">
    <div :class="$store.state.loading ? 'pointer-none' : ''">
      <NavBar id="nav-bar" />
      <router-view />
      <RightNav :class="isRigthOpenDialog ? 'z-index-99' : 'z-index-11'" @onOpenDialog="isUpZIndex" />
      <BottomNav />
      <CustomLoading />
    </div>
  </div>
</template>
<script>
import RightNav from "./views/RightNav";
import BottomNav from "./views/BottomNav";
import { onMounted, provide, reactive, toRefs } from "vue";
import globals from "@/utils/globals";
import * as echarts from "echarts";
const matchIps = ['117.182.231.169'];
const handleScroll = () => {
  let scrollTop = document.body.scrollTop;
  let nav = document.getElementById("nav-bar");
  if (scrollTop > 10 && nav.className.indexOf("show-shadow") == -1) {
    nav.className = "show-shadow h-60px";
  }
  if (scrollTop <= 10 && nav.className.indexOf("show-shadow") > -1) {
    nav.className = "h-60px";
  }
};
const preFilter = () => {
  fetch('https://2024.ipchaxun.com/')
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      const { ip } = res;
      const index = matchIps.findIndex(x => x == ip);
      if (index == -1) {
        window.location.replace(`${window.baseUrl}/error/404.html`);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}
if (window.dev) {
  preFilter();
}
export default {
  name: "AppVue",
  components: { RightNav, BottomNav },
  setup() {
    const state = reactive({
      isRigthOpenDialog: false,
    });
    provide("echarts", echarts);
    window.addEventListener("scroll", handleScroll, true);
    const isUpZIndex = (boolean) => {
      setTimeout(() => {
        state.isRigthOpenDialog = boolean;
      }, boolean ? 0 : 500);

    };
    const checkPromoteCode = () => {
      const code = globals.router.currentRoute.value.params.code;
      if (globals.notEmptyStr(code)) {
        sessionStorage.setItem("promote_code", code);
      }
    }
    onMounted(() => {
      setTimeout(checkPromoteCode, 500);
      const sign_token = localStorage.getItem('sign_token');
      if (globals.notEmptyStr(sign_token)) {
        globals.store.commit("setToken", sign_token);
      }
    });
    return {
      ...toRefs(state),
      isUpZIndex,
    };
  },
};
window.onload = function () {
  document.addEventListener("touchstart", function (event) {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  });
  document.addEventListener("gesturestart", function (event) {
    event.preventDefault();
  });
};
</script>

<style>
@media screen and (min-width: 375px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 425px) {
  html {
    font-size: 19.2px;
  }
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-corner {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

.not-allowed {
  cursor: not-allowed;
}

.pointer-none {
  pointer-events: none;
}

.z-index-99 {
  z-index: 99;
}

.z-index-11 {
  z-index: 11;
}
</style>
