<template>
  <div class="custom-loading flex-center" v-show="$store.state.loading">
    <div>
      <div class="flex-center" style="margin: 10px">
        <van-loading size="36" color="#fff" />
      </div>
      Loading...
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  name: "LoadingVue",
  setup() {
    const state = reactive({});
    return {
      ...toRefs(state),
    };
  },
};
</script>
<style scoped>
.custom-loading {
  color: #fff;
  background-color: #4D4D4D;
  border-radius: 5px;
  height: 120px;
  width: 120px;
  position: absolute;
  top: 50%;
  left: calc(50%);
  transform: translate(-50%, -50%);
  font-size: 14px;
  z-index: 999999999;
}
</style>